@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Kufi-Regular";
  src: url("./assets/fonts/droidArabicKufi/Droid-Arabic-Kufi-Regular.ttf");
}

@font-face {
  font-family: "Hind-Siliguri-Regular";
  src: url("./assets/fonts/hindSiliguri/HindSiliguri-Regular.ttf");
}

html {
  color: #777777
}

body {
  overflow-x: hidden;
}

.p {
  padding: 0px 40px 55px 35px;
}

.btn {
  @apply px-5 py-3 bg-primary text-sm text-white font-medium
}

.title_footer {
  @apply text-xl capitalize text-primary font-bold mb-2 md:mb-0
}

.social_container {
  @apply bg-white h-9 w-9 flex items-center justify-center;
}

.nav-link {
  @apply p-4 text-white relative text-[15px]
}


.nav-link>a::before, .nav-link>button::before {
  @apply absolute content-[''] top-1/2 left-0 h-5 w-[1px] opacity-40 bg-white -translate-y-1/2
}


#banner_swiper .swiper-button-next,
#banner_swiper .swiper-button-prev {
  @apply !w-12 !h-12 md:!w-16  md:!h-16 rounded-[50%] border-2 border-white !text-2xl
}

#banner_swiper .swiper-button-next::after,
#banner_swiper .swiper-button-prev::after {
  @apply !text-xl md:!text-2xl !text-white
}



#banner_swiper .swiper-pagination-bullets {
  @apply flex select-none !-translate-x-1/2 !left-1/2 gap-3 flex-row items-end lg:flex-col lg:!left-[8%] lg:!top-1/2 !transform lg:!-translate-y-1/2 h-fit !w-fit
}

#banner_swiper .swiper-pagination-bullet {
  @apply !w-4 !h-4
}

#banner_swiper .swiper-pagination-bullet {
  @apply !bg-white !opacity-100
}

#banner_swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply border-[3px] box-border border-[#00908d]
}

button svg {
  @apply mx-2
}

#drawer_dropdown button {
  @apply flex flex-row-reverse
}

a {
  @apply cursor-pointer
}

.search_overlay {
  @apply top-0 left-0 bg-black bg-opacity-90 h-screen w-full z-10
}

.sideBar_Overlay {
  @apply top-0 bg-black bg-opacity-90 h-screen w-full z-10
}

.sideBar {
  @apply top-0 bg-white h-screen z-10 opacity-0
}

.input_search_container {
  @apply text-lg h-[70px] font-medium absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] bg-white w-0
}

.arrowClose_container {
  @apply absolute left-0 right-0 top-1/2 transform translate-y-[-50%] m-auto rounded-[50%] text-center bg-[#c12b29] w-[70px] cursor-pointer shadow-xl border-white border-b-[4px] opacity-0
}

.arrowClose {
  @apply relative block h-[4.4rem] w-12 m-auto text-xl text-white
}

.search_overlay::before {
  @apply absolute left-0 top-full w-full h-[560px] bg-[url("./assets/images/waves-shape.png")] bg-no-repeat bg-center bg-cover mt-0 content-['']
}

.overlay_product {
  @apply absolute bottom-0 w-full h-1/3 p-3 text-white flex justify-between items-center bg-gradient-overlay-product translate-y-full transition-transform duration-500 ease-in
}

.product_card {
  @apply overflow-hidden
}

.product_card img {
  @apply transition-transform duration-500 ease-in
}

.product_card:hover img {
  @apply scale-105 transition-transform duration-500 ease-in
}

.product_card:hover .overlay_product {
  @apply transition-transform duration-500 ease-in translate-y-0
}

.testimonial-item {
  @apply p-8 text-center shadow-testimonial my-5
}

.testimonial-img {
  @apply rounded-[50%] w-20 mx-auto mt-5
}

.input {
  @apply px-6 py-2 text-sm h-14 border border-gray-400 text-black placeholder:text-black
}

.form-control {
  @apply block w-full px-2 text-base text-[#495057] border border-[#ced4da] rounded
}
.post-thumb{
  width: 6rem;
}
.fixed-nav {
  @apply top-0 left-0 w-full fixed bg-black;
}


.pagination {
  @apply flex justify-center
}

ul.pagination {
  @apply list-none p-0
}

ul.pagination li {
  @apply flex justify-center w-8 border border-[#e2e2e2] text-2xl
}

ul.pagination li a {
  @apply no-underline text-xl
}
ul.pagination li:not(.disabled) a {
  @apply text-primary
}

ul.pagination li.active a {
  @apply text-white
}
ul.pagination li.active {
  @apply bg-primary
}

.page-selection {
  @apply w-12 h-8 text-primary
}

.pagination-wrapper {
  @apply flex justify-between mt-2
}
ul.pagination li.disabled a{
  @apply text-gray-300 cursor-default
}
